import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51TTY+bMBC951f4UmlzcAQsS1VyrNS/0GNl8ADuGo9lTEK22v9eefgIIdGq21vy3gxv3pvx4Vfcvw5ZOzyzPzvGLHbKKzQ5q9QA8rhjTJkOfM6i8LsQ5WvtsDeSl6jR5ewk3BPnoqqUAb6iW5RCj0X70PnGlZEwbBomdKzeH3fvu8M8T/ov80jVWS0uOas0DAEQWtWGKw9tl7MSjAcX4N9951V14SUaD8avqU8P9kKDcT7/j3J2vAHiLZCMwFlJ38w6Szel04CqG7/lYuJaZfhjPiG+wIF36k2ZOmcFOgmOF0hhVGh8oGBjjvBCdLBfqs6TQBpRrjqUzaLxIQuYFVKSSBLZgSWpJY3rghxo4dUJPncneAKnxYVbYWB9L+SqERLPm16LNvRM5FRLrp2Qqu9yFifjaK0YFg+l0OVTHEWnhnH2nNiBGrH3wWnODBq4WXK2uT5RdKh7T+Y82pwlk4gbvx8y+a9r+kpCH26q4dn9orKHi0pfRuOuVoYX6D22cx4rzW+kefd27h5JIDgY+cHDmo6CUyZpNAdP+oSJ3mPAamHnlNaTHCiSH4ge3E/lm++N0tKBGeNff/yuV1DNWuu6+Bv/826WmKLoC23/BK7SeOaX65jLkUcs3egVj1OjkKRyUI6XUqLuW3Pcvf8FpEzSSVoFAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mx6';
export var confirmModalContainer = '_1ukx6mxb';
export var confirmModalContent = '_1ukx6mxa';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mx5';
export var modalContentWrapper = '_1ukx6mx4';
export var modalDescription = '_1ukx6mx8';
export var modalFooter = '_1ukx6mx9';
export var modalHeader = '_1ukx6mx7';
export var modalOverlay = '_1ukx6mx3';
export var widthVar = 'var(--_1ukx6mx0)';